<template>
    <CKEditor v-model="text" initFocus />
</template>

<script>
import CKEditor from '@apps/CKEditor'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    components: {
        CKEditor
    },
    data() {
        return {
            text: ''
        }
    },
    created() {
        this.text = this.widget.random_html
    }
}
</script>